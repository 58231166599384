'use strict';

var $ = require('../internals/export');
var hasOwn = require('../internals/has-own-property');

// `Object.hasOwn` method
// https://tc39.es/ecma262/#sec-object.hasown
$({
  target: 'Object',
  stat: true
}, {
  hasOwn: hasOwn
});