import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SchoolConfiguration } from '../model/schoolConfiguration';
import {SchoolYear} from "../model/schoolYear";

@Injectable()
export class SchoolService {

  private schoolConfigurationUrl = 'api/school/';  // URL to web api
  private selectedSchool: SchoolConfiguration;
  /**
   * Class verantwoordelijk voor het ophalen van de school / opleidingsinformatie
   */
  constructor(private http: HttpClient) { }

  setSchool(schoolConfiguration: SchoolConfiguration) {
    this.selectedSchool = schoolConfiguration;
  }

  getAllSchools(): Observable<SchoolConfiguration[]> {
    return this.http.get<SchoolConfiguration[]>(this.schoolConfigurationUrl);
  }

  getReferralSchools(): Observable<SchoolConfiguration[]> {
    return this.http.get<SchoolConfiguration[]>(this.schoolConfigurationUrl + "referrals");
  }

  // Deprecated?
  getActiveSchools(): Observable<SchoolConfiguration[]> {
    return this.http.get<SchoolConfiguration[]>(this.schoolConfigurationUrl + 'active');
  }

  getPoSchoolsByDates(startDate: string): Observable<SchoolConfiguration[]> {
    let url = this.schoolConfigurationUrl + 'activebydate?startDate=' + startDate;
    return this.http.get<SchoolConfiguration[]>(url);
  }

  getSchoolById(id: string): Observable<SchoolConfiguration> {
    return this.http.get<SchoolConfiguration>(this.schoolConfigurationUrl + id);
  }

  getSchoolByDirectLink(directLink: string): Observable<SchoolConfiguration> {
    return this.http.get<SchoolConfiguration>(this.schoolConfigurationUrl + 'dl/' + directLink);
  }

  getSchool(): SchoolConfiguration {
    return this.selectedSchool;
  }

  filterSchoolsByIsVisibleIsTrue(schools: SchoolConfiguration[]): SchoolConfiguration[] {
    return schools.filter((school) => school.isVisible);
  }

  filterSchoolsByDirectLink(schools: SchoolConfiguration[], directLink: string): SchoolConfiguration {
    const filteredSchools = schools.filter((school => school.directLink === directLink));
    return filteredSchools.length !== 1 ? null : filteredSchools[0];
  }
}
