'use strict';

var isCallable = require('../internals/is-callable');
var isObject = require('../internals/is-object');
var definePropertyModule = require('../internals/object-define-property');
var isPrototypeOf = require('../internals/object-is-prototype-of');
var wellKnownSymbol = require('../internals/well-known-symbol');
var makeBuiltIn = require('../internals/make-built-in');
var HAS_INSTANCE = wellKnownSymbol('hasInstance');
var FunctionPrototype = Function.prototype;

// `Function.prototype[@@hasInstance]` method
// https://tc39.es/ecma262/#sec-function.prototype-@@hasinstance
if (!(HAS_INSTANCE in FunctionPrototype)) {
  definePropertyModule.f(FunctionPrototype, HAS_INSTANCE, {
    value: makeBuiltIn(function (O) {
      if (!isCallable(this) || !isObject(O)) return false;
      var P = this.prototype;
      return isObject(P) ? isPrototypeOf(P, O) : O instanceof this;
    }, HAS_INSTANCE)
  });
}