'use strict';

var $ = require('../internals/export');
var repeat = require('../internals/string-repeat');

// `String.prototype.repeat` method
// https://tc39.es/ecma262/#sec-string.prototype.repeat
$({
  target: 'String',
  proto: true
}, {
  repeat: repeat
});