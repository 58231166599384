'use strict';

var $ = require('../internals/export');
var log1p = require('../internals/math-log1p');

// `Math.log1p` method
// https://tc39.es/ecma262/#sec-math.log1p
$({
  target: 'Math',
  stat: true
}, {
  log1p: log1p
});