'use strict';

var $ = require('../internals/export');
var isIntegralNumber = require('../internals/is-integral-number');

// `Number.isInteger` method
// https://tc39.es/ecma262/#sec-number.isinteger
$({
  target: 'Number',
  stat: true
}, {
  isInteger: isIntegralNumber
});