'use strict';

var $ = require('../internals/export');
var $isExtensible = require('../internals/object-is-extensible');

// `Object.isExtensible` method
// https://tc39.es/ecma262/#sec-object.isextensible
// eslint-disable-next-line es/no-object-isextensible -- safe
$({
  target: 'Object',
  stat: true,
  forced: Object.isExtensible !== $isExtensible
}, {
  isExtensible: $isExtensible
});