'use strict';

var $ = require('../internals/export');
var sign = require('../internals/math-sign');

// `Math.sign` method
// https://tc39.es/ecma262/#sec-math.sign
$({
  target: 'Math',
  stat: true
}, {
  sign: sign
});