'use strict';

var $ = require('../internals/export');
var isArray = require('../internals/is-array');

// `Array.isArray` method
// https://tc39.es/ecma262/#sec-array.isarray
$({
  target: 'Array',
  stat: true
}, {
  isArray: isArray
});