'use strict';

var $ = require('../internals/export');
var createHTML = require('../internals/create-html');
var forcedStringHTMLMethod = require('../internals/string-html-forced');

// `String.prototype.anchor` method
// https://tc39.es/ecma262/#sec-string.prototype.anchor
$({
  target: 'String',
  proto: true,
  forced: forcedStringHTMLMethod('anchor')
}, {
  anchor: function anchor(name) {
    return createHTML(this, 'a', 'name', name);
  }
});