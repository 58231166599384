'use strict';

var $ = require('../internals/export');

// `Number.EPSILON` constant
// https://tc39.es/ecma262/#sec-number.epsilon
$({
  target: 'Number',
  stat: true,
  nonConfigurable: true,
  nonWritable: true
}, {
  EPSILON: Math.pow(2, -52)
});