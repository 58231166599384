'use strict';

var $ = require('../internals/export');
var is = require('../internals/same-value');

// `Object.is` method
// https://tc39.es/ecma262/#sec-object.is
$({
  target: 'Object',
  stat: true
}, {
  is: is
});