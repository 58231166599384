import {TemplateRef} from "@angular/core";
import {SignUp} from "../../../shared/model/signup";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import {FormService} from "../../../shared/services/form.service";
import {SchoolService} from "../../../shared/services/school.service";
import {ColorService} from "../../../shared/services/color.service";
import {WorkflowService} from "../../../shared/services/workflow.service";
import {SignUpService} from "../../../shared/services/signup.service";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {StatusService} from "../../../shared/services/status.service";
import {PageService} from "../../../shared/services/page.service";
import {SchoolConfiguration} from "../../../shared/model/schoolConfiguration";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Page} from "../../../shared/model/page";
import {ModalContentComponent} from "../../../shared/components/app-modal/app-modal-content.component";

export abstract class BaseHomeOverviewComponent {

  formId: string;

  public modalRef: BsModalRef;

  constructor(private router: Router,
              private readonly route: ActivatedRoute,
              private notificationService: NotificationService,
              private formService: FormService,
              private schoolService: SchoolService,
              private colorService: ColorService,
              private modalService: BsModalService,
              private signUpService: SignUpService,
              private workflowService: WorkflowService,
              private statusService: StatusService,
              private pageService: PageService,
  ) { }

  getRouter(): Router {
    return this.router;
  }
  getFormService(): FormService {
    return this.formService;
  }
  getSchoolService(): SchoolService {
    return this.schoolService;
  }
  getColorService(): ColorService {
    return this.colorService;
  }
  getSignUpService(): SignUpService {
    return this.signUpService;
  }
  getWorkflowService(): WorkflowService {
    return this.workflowService;
  }
  getNotificationService(): NotificationService {
    return this.notificationService;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  openModalWithTemplateParameter(template: TemplateRef<any>, param: any) {
    const initialState: ModalOptions = {
      initialState: {
        data: param
      }
    };

    this.modalRef = this.modalService.show(template, initialState);
  }

  getModalInitialStateData(): any {
    return this.modalService.config.initialState.data;
  }

  determineSchoolConfigEnabled(schoolConfig: SchoolConfiguration): Observable<boolean> {
    return this.statusService.getStatus().pipe(
        map((status) => {
          return !(!status.portalOpen || !schoolConfig.isEnabled);
        }));
  }

  handlePortalClosedError() {
    this.pageService
        .getPage('welkom_gesloten')
        .pipe().subscribe((page: Page) => {

      const initialState: ModalOptions = {
        initialState: {
          title: page.title,
          text: page.body,
          routingUrl: "/home",
          type: 'html' //Type is used to determine if innerHtml is filled with text or if text is used.
        }
      };
      this.modalRef = this.modalService.show(ModalContentComponent, initialState);
      this.modalRef.content.closeBtnName = 'Sluiten';
    });
  }

  edit(signup: SignUp): void {
    this.schoolService.getSchoolById(signup.school.schoolId).subscribe((s) => {
      this.determineSchoolConfigEnabled(s).subscribe((portalOpenAndEnabled: boolean) => {
        if (!portalOpenAndEnabled && !signup.isTest) {
          this.handlePortalClosedError();
          return;
        }

        //s.isInflux = this.selectedSchoolYear.isInfluxYear;
        this.schoolService.setSchool(s);
        this.colorService.changeColor(s.stylingId);
        this.signUpService.setSignUp(signup);

        const routerCommand = '/' + signup.lastStep;
        if (signup.formId !== undefined && signup.formId !== null) {
          this.formService.getFormById(this.formId ? this.formId : signup.formId).subscribe(form => {
            this.formService.setForm(form);
            this.router.navigate([routerCommand]);
          });
        } else {
          this.formService.getFormById(this.formId ? this.formId : s.formId).subscribe(form => {
            this.formService.setForm(form);
            this.router.navigate([routerCommand]);
          });
        }
      })
    });
  }

  setFormId(formId: string) {
    this.formId = formId;
  }

  formatDate(dateTime: string): string {
    const year = dateTime.substring(0, 4);
    const month = dateTime.substring(5, 7);
    const day = dateTime.substring(8, 10);

    const time = dateTime.substring(11, 16);
    const date = day.concat('-').concat(month).concat('-').concat(year);

    return date.concat(' ').concat(time);
  }
}
